import Service from "../Service";
import axios from "../../plugins/axios-plugin";

class VkUserSubscriptionService extends Service{
    basePath = 'vk/subscription'

    activate = async (data) => {
        return await axios.post(this.basePath + '/activate', data);
    };
}

export default VkUserSubscriptionService;