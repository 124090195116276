import {Group, Panel, PanelHeader, Spinner} from "@vkontakte/vkui";
import * as React from "react";
import {useEffect, useState} from "react";
import GiftCodeService from "../../services/gift/GiftCodeService";
import {VkUser} from "../../components/VkUser";
import {GiftsList} from "../../components/gift/GiftsList";

export const GiftPanel = ({...props}) => {
    const giftCodeService = new GiftCodeService();
    const [gifts, setGifts] = useState([]);
    const [fetching, setFetching] = React.useState(false);

    const fetchGifts = async (filter) => {
        try {
            setFetching(true);
            await giftCodeService.all().then(res => {
                setGifts(giftCodeService.list);
                setFetching(false);
            })
        } catch (error) {
            props.openError(error.message);
            console.error('Error fetching user data:', error.message);
        }
    }

    useEffect( () => {
        fetchGifts();
    }, []);

    return (
        <Panel>
            <PanelHeader after={<VkUser user={props.user} mode={'plain'} />}>Подарки</PanelHeader>
            <Group>
                {
                    !fetching ? <GiftsList gifts={gifts} routeNavigator={props.routeNavigator} /> : <Spinner size="medium" style={{ margin: '20px 0' }} />
                }
            </Group>
        </Panel>
    )
}