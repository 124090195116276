import axios from "../../plugins/axios-plugin";
import Service from "../Service";

class GiftCodeService extends Service {
    basePath = "gift/code";

    async checkGiftCode(data){
        return await axios.post('/gift/code/check',  data);
    };

    async activateSubscription(data){
        return await axios.post('/gift/code/activate-subscription',  data);
    };

    async setGiftIsUsed(data){
        return await axios.post('/gift/code/set-used',  data);
    };
}

export default GiftCodeService;