import * as React from "react";
import {CustomModal} from "../../components/vkUI/CustomModal";
import {ActionForm} from "../../components/action/ActionForm";

export const ActionUpdateModal = ({...props}) => {
    return (
        <CustomModal id={'action_update_modal'} headerText={'Редактировать действие'}>
            <ActionForm
                setPopout={props.setPopout}
            />
        </CustomModal>
    )
}