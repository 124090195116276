import {Div, Text} from "@vkontakte/vkui";
import {Form} from "../form/Form";
import * as React from "react";
import VkService from "../../services/vk/VkService";
import {useParams, useRouteNavigator} from "@vkontakte/vk-mini-apps-router";

export const VkSubscribeActionForm = ({setPopout}) => {
    const vkService = new VkService(setPopout);
    const [loading, setLoading] = React.useState(false);
    const routeNavigator = useRouteNavigator();
    const params = useParams();
    const queryParams = new URLSearchParams(window.location.search);

    const onSubmitHandler = async() => {
        try {
            await vkService.subscribe({'action_uuid': params.uuid, booster_uuid: queryParams.has('booster_uuid') ? queryParams.get('booster_uuid') : null}).then(res => {
                routeNavigator.hideModal()
                setLoading(false)
            })
        } catch (exception) {
            setLoading(false)
        }
    }

    return (
        <Form loading={loading} onSubmit={onSubmitHandler} submitButtonText={'Подписаться'}>
            <Div style={{padding: 16}}>
                <Text>
                    После нажатия кнопки вы подпишитесь на сообщество Вконтакте.
                </Text>
            </Div>
        </Form>
    )
}