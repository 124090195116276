import { createSlice } from '@reduxjs/toolkit'

export const adminGiftsSlice = createSlice({
    name: 'adminGiftsSlice',
    initialState: {
        list: [],
    },
    reducers: {
        setAdminGiftsInStore: (state, action) => {
            state.list = action.payload;
        },
        addAdminGiftInStore: (state, action) => {
            state.list.unshift(action.payload);
        },
        updateAdminGiftInStore(state, action) {
            state.list = state.list.map(item => {
                if (item.id === action.payload.id) {
                    return {
                        ...item,
                        ...action.payload
                    };
                }
                return item;
            });
        },
        deleteAdminGiftInStore(state, action) {
            state.list = state.list.filter(item => {
                return item.id !== action.payload;
            })
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    setAdminGiftsInStore,
    addAdminGiftInStore,
    updateAdminGiftInStore,
    deleteAdminGiftInStore
} = adminGiftsSlice.actions

export default adminGiftsSlice.reducer