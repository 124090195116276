import axios from "../../plugins/axios-plugin";
import Service from "../Service";

class UserService extends Service {
    basePath = 'user';

    getUserAnalytic = async (data) => {
        try {
            const response = await axios.get('/user/client/analytic', data);
            return response.data.data;
        } catch (error) {
            throw new Error(error.response.data.message);
        }
    };
}

export default UserService;
