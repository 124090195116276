import Service from "../Service";
import {ScreenSpinner} from "@vkontakte/vkui";
import axios from "../../plugins/axios-plugin";
import * as React from "react";

class UserAdminSettingsService extends Service {
    basePath = 'user/admin/settings';

    async show() {
        try {
            const response = await axios.get(this.basePath + '/show');
            this.model = response.data.data;
        } catch (error) {
            throw new Error(error.response.data.message);
        }
    }

    async update(data) {
        this.setPopout(<ScreenSpinner state="loading"/>);
        try {
            await axios.post(this.basePath + '/update', data).then(res => {
                this.setPopout(<ScreenSpinner state="done">Успешно</ScreenSpinner>);
                this.model = res.data.data;
                setTimeout(() => {
                    this.clearPopout();
                }, 1000);
            });
        } catch (exception) {
            this.clearPopout();
            throw new Error(exception)
        }
    }
}

export default UserAdminSettingsService;
