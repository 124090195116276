import * as React from "react";
import {CustomModal} from "../../components/vkUI/CustomModal";
import {BoosterForm} from "../../components/booster/BoosterForm";

export const BoosterUpdateModal = ({...props}) => {
    return (
        <CustomModal id={'booster_update_modal'} headerText={'Редактировать бустер'}>
            <BoosterForm
                setPopout={props.setPopout}
            />
        </CustomModal>
    )
}