import axios from "../../plugins/axios-plugin";
import Service from "../Service";

class VkService extends Service {
    subscribe = async (data) => {
        const response = await axios.post('vk/action/subscribe', data);
        return response.data.data;
    };

    sendReview = async (data) => {
        const response = await axios.post('vk/action/send-review', data)
        return response.data.data;
    };

    setLikesOnLastPosts = async (data) => {
        const response = await axios.post('vk/action/set-likes-on-last-posts', data);
        return response.data.data;
    };

    setLike = async (data) => {
        const response = await axios.post('vk/action/set-like', data);
        return response.data.data;
    };

    sendComment = async (data) => {
        const response =  await axios.post('vk/action/send-comment', data)
        return response.data.data;
    };

    repost = async (data) => {
        const response = await axios.post('vk/action/repost', data);
        return response.data.data;
    };

    checkSubscription = async (data) => {
        try {
            const response = await axios.get('/vk/subscription/check', data);
            return response.data.data;
        } catch (error) {
            throw new Error(error.response.data.message);
        }
    };
}

export default VkService;
