import {Group, ModalPage, ModalPageHeader, PanelHeaderButton, PanelHeaderClose, Snackbar, useAdaptivityConditionalRender, usePlatform} from "@vkontakte/vkui";
import {Icon24Dismiss, Icon28ErrorCircleOutline} from "@vkontakte/icons";
import * as React from "react";
import {useRouteNavigator} from "@vkontakte/vk-mini-apps-router";
import {useEffect} from "react";
import {useSelector} from "react-redux";
import store from "../../store";
import {clearAppErrors} from "../../features/errors/appErrorsSlice";

export const CustomModal = ({children, id, headerText}) => {
    const routeNavigator = useRouteNavigator();
    const platform = usePlatform();
    const {sizeX} = useAdaptivityConditionalRender();
    const [snackbar, setSnackbar] = React.useState(null);
    const appError = useSelector((state) => state.appErrors.error)

    const openError = (message) => {
        if (snackbar) return;
        setSnackbar(
            <Snackbar
                onClose={() => setSnackbar(null)}
                before={<Icon28ErrorCircleOutline fill="var(--vkui--color_icon_negative)"/>}
            >
                {message}
            </Snackbar>,
        );

        setTimeout(() => {
            store.dispatch(clearAppErrors())
        }, 2000)
    };

    useEffect(() => {
        if (appError) {
            openError(appError.message);
        }
    }, [appError])

    return (
        <ModalPage
            id={id}
            onClose={() => routeNavigator.back()}
            hideCloseButton={platform === 'ios'}
            settlingHeight={100}
            dynamicContentHeight={true}
            header={
                <ModalPageHeader
                    before={
                        sizeX.compact &&
                        platform === 'android' && (
                            <PanelHeaderClose className={sizeX.compact.className} onClick={() => routeNavigator.back()} />
                        )
                    }
                    after={
                        platform === 'ios' && (
                            <PanelHeaderButton onClick={() => routeNavigator.back()}>
                                <Icon24Dismiss />
                            </PanelHeaderButton>
                        )
                    }
                >
                    {headerText}
                </ModalPageHeader>
            }
        >
            <Group>
                {children}
                {snackbar}
            </Group>
        </ModalPage>
    )
}