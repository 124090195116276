import * as React from "react";
import {CustomModal} from "../../components/vkUI/CustomModal";
import {GiftForm} from "../../components/gift/GiftForm";

export const GiftCreateModal = ({...props}) => {
    return (
        <CustomModal id={'gift_create_modal'} headerText={'Добавить подарок'}>
            <GiftForm
                setPopout={props.setPopout}
            />
        </CustomModal>
    )
}