import * as React from "react";
import {CustomModal} from "../../components/vkUI/CustomModal";
import {GiftForm} from "../../components/gift/GiftForm";

export const GiftUpdateModal = ({...props}) => {
    return (
        <CustomModal id={'gift_update_modal'} headerText={'Редактировать подарок'}>
            <GiftForm
                setPopout={props.setPopout}
            />
        </CustomModal>
    )
}