import {Panel, Snackbar} from "@vkontakte/vkui";
import {useEffect, useState} from "react";
import {Icon28ErrorCircleOutline} from "@vkontakte/icons";
import * as React from "react";
import {useSelector} from "react-redux";
import store from "../../store";
import {clearAppErrors} from "../../features/errors/appErrorsSlice";

export const CustomPanel = ({children, id}) => {
    const [popout, setPopout] = useState(null);
    const clearPopout = () => setPopout(null);
    const [snackbar, setSnackbar] = React.useState(null);
    const appError = useSelector((state) => state.appErrors.error)

    const openError = (message) => {
        if (snackbar) return;
        setSnackbar(
            <Snackbar
                onClose={() => setSnackbar(null)}
                before={<Icon28ErrorCircleOutline fill="var(--vkui--color_icon_negative)"/>}
            >
                {message}
            </Snackbar>,
        );

        setTimeout(() => {
            store.dispatch(clearAppErrors())
        }, 2000)
    };

    useEffect(() => {
        if (appError) {
            openError(appError.message);
        }
    }, [appError])

    return (
        <Panel id={id}>
            {children}
            {snackbar}
        </Panel>
    )
}