import {Button, Spacing, Spinner, Title, Header, Paragraph, Group, Image} from "@vkontakte/vkui";
import {Icon20VotestTransferCircleFillTurquoise} from "@vkontakte/icons";
import * as React from "react";
import {useParams} from "@vkontakte/vk-mini-apps-router";
import {useEffect, useState} from "react";
import GiftService from "../../services/gift/GiftService";
import {CustomModal} from "../../components/vkUI/CustomModal";

export const BuyGiftModal = ({...props}) => {
    const giftService = new GiftService(props.setPopout);

    const params = useParams();

    const [loading, setLoading] = useState(true);
    const [gift, setGift] = useState(null);

    const fetchData = async () => {
        await giftService.show(params.id).then(res => {
            setGift(giftService.model);
        });
    }

    const onClickBuyGift = async () => {
        await giftService.buySelectedGift({gift_uuid: gift.uuid}).then(res => {
            setTimeout(() => {
                props.clearPopout();
                props.routeNavigator.push('/gift/code/show/' + giftService.model.uuid);
            }, 1000);
        }).catch(function (error) {
            props.clearPopout();
        })
    }

    useEffect(() => {
        fetchData().then(res => {
            setLoading(false);
        })
    }, []);

    return (
        <CustomModal headerText={'Купить подарок'} id={'buy_gift_modal'}>
            {
                gift ? <Group style={{padding: '8px'}}>
                    <div style={{marginBottom: '10px'}}>
                        <Image src={gift.banner} size={256} />
                        <Header style={{padding: 0}}>
                            <div style={{display: 'flex', alignItems: 'center', gap: '3px', color: 'black', borderRadius: 10, fontWeight: 700}}>
                                {gift.amount} <Icon20VotestTransferCircleFillTurquoise/>
                            </div>
                        </Header>
                        <Title style={{marginBottom: 5}}>{gift.name}</Title>
                        <Paragraph>
                            {gift.description}
                        </Paragraph>
                    </div>
                    <Spacing size={16}/>
                    <Button
                        size="l"
                        mode="primary"
                        stretched
                        onClick={() => onClickBuyGift()}
                    >
                        Купить
                    </Button>
                </Group> : <Spinner size={'medium'}/>
            }
        </CustomModal>
    )
}