import axios from "../plugins/axios-plugin";
import {ScreenSpinner} from "@vkontakte/vkui";
import * as React from "react";

class Service {

    basePath = null;
    model = null;
    list = [];
    formErrors = [];

    setPopout = null;
    clearPopout = null;

    constructor(setPopout) {
        this.setPopout = setPopout;
        this.clearPopout = () => setPopout(null);
    }

     async all(params) {
        try {
            const response = await axios.get(this.basePath + '/all', {
                params: params,
            });
            this.list = response.data.data;
        } catch (error) {
            throw new Error(error.response.data.message);
        }
    }

    async show(uuid) {
        try {
            const response = await axios.get(this.basePath + '/show/' + uuid);
            this.model = response.data.data;
        } catch (error) {
            throw new Error(error.response.data.message);
        }
    }

     async create(data) {
         this.setPopout(<ScreenSpinner state="loading"/>);
         try {
             await axios.post(this.basePath + '/store', data).then(res => {
                 this.setPopout(<ScreenSpinner state="done">Успешно</ScreenSpinner>);
                 this.model = res.data.data;
                 setTimeout(() => {
                     this.clearPopout();
                 }, 1000);
             });
         } catch (exception) {
             this.clearPopout();
             throw new Error(exception)
         }
    }

    async update(data) {
        this.setPopout(<ScreenSpinner state="loading"/>);

        let uuid = data.uuid;

        console.log(data)
        console.log(uuid)

        try {
            await axios.post(uuid ? this.basePath + '/update/' + uuid : this.basePath + '/update', data).then(res => {
                this.setPopout(<ScreenSpinner state="done">Успешно</ScreenSpinner>);
                this.model = res.data.data;
                setTimeout(() => {
                    this.clearPopout();
                }, 1000);
            });
        } catch (exception) {
            this.clearPopout();
            throw new Error(exception)
        }
    }

    async delete(actionId) {
        return await axios.delete('/action/delete/' + actionId);
    };

}

export default Service;