import Service from "../Service";
import axios from "../../plugins/axios-plugin";
class VkUserService extends Service{
    basePath = 'vk/users'

    getActions = async (vkUserUuid) => {
        return await axios.get(this.basePath + '/show/' + vkUserUuid + `/actions`)
    }

    getGifts = async (vkUserUuid) => {
        return await axios.get(this.basePath + '/show/' + vkUserUuid + `/gifts`)
    }
}

export default VkUserService;