import {Avatar, Button, ButtonGroup, Group, Panel, PanelHeader, PanelHeaderBack, Placeholder, RichCell, Spinner} from "@vkontakte/vkui";
import * as React from "react";
import {useEffect, useState} from "react";
import {allUsers} from "../../../services/vk/VkUserService";
import {Icon56ServicesOutline, Icon56UserAddOutline} from "@vkontakte/icons";
import ActionUserService from "../../../services/action/ActionUserService";

export const AdminVkUsersActionsPanel = ({openError, routeNavigator}) => {
    const actionUserService = new ActionUserService();
    const [fetching, setFetching] = useState(true);
    const [userActions, setUserActions] = useState(null);

    const fetchPanelData = async () => {
        try {
            await actionUserService.all().then(res => {
                setUserActions(actionUserService.list);
                setFetching(false);
            });
        } catch (error) {
            openError(error.message)
        }
    }

    useEffect(() => {
        fetchPanelData();
    }, []);

    return (
        <Panel>
            <PanelHeader
                delimiter="spacing"
                before={<PanelHeaderBack onClick={() => routeNavigator.back()} />}
            >
                Действия подписчиков
            </PanelHeader>
            {
                !fetching ? <Group id="tab-content-clients" aria-labelledby="tab-groups" role="tabpanel">
                    {
                        userActions.length ?
                            userActions.map((userAction, index) => (
                                <RichCell
                                    key={index}
                                    before={<Avatar size={72} src={userAction.user.photo} />}
                                    subhead={userAction.user.city}
                                    text={userAction.action.name}
                                    after={userAction.created_at}
                                >
                                    {userAction.user.name}
                                </RichCell>
                            )) : <Placeholder
                                icon={<Icon56UserAddOutline />}
                                header="Никто из пользователей еще не прошел бустер"
                            >
                                Разместите QR-код на прохождение и наблюдатей как растет ваше сообщество.
                            </Placeholder>
                    }
                </Group> : <Group><Spinner size={'medium'} /></Group>
            }
        </Panel>
    )
}
