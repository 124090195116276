import {Banner, Button, Div, Group} from "@vkontakte/vkui";
import {Icon28GiftCircleFillYellow} from "@vkontakte/icons";
import * as React from "react";

export const GiftBanner = ({gift, showGift, getGift, isShow}) => {

    const getGiftBannerHeader = () => {
        if (gift && gift.is_used) {return 'Подарок уже использован';}
        return 'Получите подарок';
    }

    const getGiftBannerSubHeader = () => {
        if (gift && gift.is_used) {return 'Вы уже получили подарок за прохождение этого бустера';}
        return 'Бустер успешно пройден';
    }

    const getGiftBannerActions = () => {
        if (gift) {
            return <Button disabled={gift.is_used} onClick={() => showGift()}>Показать подарок</Button>;
        }

        return <Button onClick={() => getGift()}>Получить</Button>;
    }

    return (
        isShow  &&
        <Group>
            <Div>
                <Banner
                    before={<Icon28GiftCircleFillYellow />}
                    header={getGiftBannerHeader()}
                    subheader={getGiftBannerSubHeader()}
                    actions={getGiftBannerActions()}
                />
            </Div>
        </Group>
    )
}