import * as React from "react";
import {CustomModal} from "../../components/vkUI/CustomModal";
import {BoosterForm} from "../../components/booster/BoosterForm";

export const BoosterCreateModal = ({...props}) => {
    return (
        <CustomModal id={'booster_create_modal'} headerText={'Добавить бустер'}>
            <BoosterForm
                setPopout={props.setPopout}
            />
        </CustomModal>
    )
}