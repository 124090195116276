import axios from "../../plugins/axios-plugin";
import Service from "../Service";
import {ScreenSpinner} from "@vkontakte/vkui";
import * as React from "react";

class GiftService extends Service {
    basePath = 'gift';

    getRandomGift = async (data) => {
        try {
            const response = await axios.post('/gift/get-random', data);
            return response.data.data;
        } catch (error) {
            throw new Error(error.response.data.message);
        }
    };

    buySelectedGift = async (data) => {
        this.setPopout(<ScreenSpinner state="loading"/>);
        await axios.post('/gift/buy-selected', data).then(res => {
            if (res.data.successful) {
                this.setPopout(<ScreenSpinner state="done">Успешно</ScreenSpinner>);
                this.model = res.data.data;
                setTimeout(() => {
                    this.clearPopout();
                }, 1000);
            } else {
                this.clearPopout();
                throw new Error(res.data.data.message);
            }
        })
    };

    getSelectedGift = async (data) => {
        try {
            const response = await axios.post('/gift/get-selected', data);
            return response.data.data;
        } catch (error) {
            throw new Error(error.response.data.message);
        }
    };
}

export default GiftService;