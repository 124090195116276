import axios from 'axios';
import {addFormErrors, clearFormErrors} from "../features/errors/formErrorsSlice";
import store from "../store";
import {addAppErrors} from "../features/errors/appErrorsSlice";

// Создайте новый экземпляр Axios
const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL + '/api',
    headers: {
        'Content-Type': 'multipart/form-data, application/json'
    }
});

// Добавьте интерцептор для запросов
instance.interceptors.request.use(config => {
    config.headers.Authorization = 'Bearer ' + localStorage.getItem('token');
    return config;
}, error => {
    return Promise.reject(error);
});

// Добавьте интерцептор для ответов
instance.interceptors.response.use(response => {
    // Здесь вы можете обрабатывать ответы, например, проверять на статус ошибки и т.д.
    store.dispatch(clearFormErrors());

    if (response.data.code === 500) {
        store.dispatch(addAppErrors(response.data.data.message))
    }

    if (!response.data.successful) {
        store.dispatch(addAppErrors(response.data.data.message))
    }

    return response;
}, error => {
    if (error.response.status === 422) {
        store.dispatch(addFormErrors(error.response.data.errors));
    }

    return Promise.reject(error);
});

export default instance;
