import {Avatar, Button, ButtonGroup, Link, Placeholder, Spinner} from "@vkontakte/vkui";
import * as React from "react";
import {Icon12Delete, Icon12EyeSlashOutline, Icon12Pencil, Icon12View, Icon56UsersOutline} from "@vkontakte/icons";
import {GroupContentList} from "../vkUI/GroupContentList";
import {GroupContent} from "../vkUI/GroupContent";
import {useEffect} from "react";
import VkAdminGroupService from "../../services/vk/VkAdminGroupService";
import {useRouteNavigator} from "@vkontakte/vk-mini-apps-router";

export const AdminVkGroupsList = ({limit}) => {
    const vkGroupService = new VkAdminGroupService();
    const [fetching, setFetching] = React.useState(true);
    const [vkGroups, setVkGroups] = React.useState([]);
    const routeNavigator = useRouteNavigator();

    const fetchPanelData = async () => {
        await vkGroupService.all({limit: limit}).then(res => {
            setVkGroups(vkGroupService.list)
            setFetching(false);
        });
    }

    const syncVkGroups = async () => {
        setFetching(true)
        await vkGroupService.syncGroups().then(res => {
            setVkGroups(res.data.data);
            setFetching(false);
        })
    }

    useEffect(() => {
        fetchPanelData()
    }, []);

    return (
        <GroupContent
            header={'Сообщества'}
            action={
                <Link onClick={() => syncVkGroups()}>
                    Синхронизировать
                </Link>
            }>
            {
                !fetching ?
                    vkGroups.length ?
                        vkGroups.map((vkGroup, index) => (
                            <GroupContentList
                                key={vkGroup.id}
                                onClick={() => routeNavigator.push('/admin/groups/show/' + vkGroup.group_id)}
                                content={vkGroup.name}
                                caption={vkGroup.description}
                                before={vkGroup.photo ? <Avatar size={40} src={vkGroup.photo} /> : null}
                                after={
                                    <ButtonGroup>
                                        <Button before={<Icon12View />} onClick={() => routeNavigator.push('/admin/groups/show/' + vkGroup.group_id)} mode="primary" />
                                    </ButtonGroup>
                                }
                            />
                        )) :  <Placeholder icon={<Icon56UsersOutline />}>
                            Пока еще нет сообществ
                        </Placeholder> : <Spinner size={'medium'} />
            }
        </GroupContent>
    )
}