import * as React from "react";
import {CustomModal} from "../../components/vkUI/CustomModal";
import {useParams, useRouteNavigator} from "@vkontakte/vk-mini-apps-router";
import VkAdminGroupService from "../../services/vk/VkAdminGroupService";
import {useEffect} from "react";
import {FormField} from "../../components/form/FormField";
import {Form} from "../../components/form/Form";

export const VkGroupUpdateModal = ({...props}) => {
    const vkGroupService = new VkAdminGroupService(props.setPopout);

    const params = useParams();
    const routeNavigator = useRouteNavigator();

    const [formData, setFormData] = React.useState({});
    const [loading, setLoading] = React.useState(false);

    const handler = (name, value) => {
        // Обновляем объект formData, сохраняя предыдущее состояние
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const fetchData = async () => {
        await vkGroupService.show(params.groupId).then(res => {
            setFormData(vkGroupService.model)
            handler('uuid', params.groupId)
        });
    }

    const onSubmit = async () => {
        await vkGroupService.update(formData).then(res => {
            setTimeout(() => {
                setLoading(false);
                routeNavigator.hideModal();
            }, 1000);
        }).catch(function (error) {
            console.log(error)
            setLoading(false);
        });
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <CustomModal id={'admin_vk_group_update_modal'} headerText={'Редактировать сообщество'}>
            <Form mode="vertical" onSubmit={onSubmit} loading={loading}>
                <FormField
                    title={'Описание'}
                    type={'input'}
                    name={'description'}
                    value={formData.description}
                    setValue={handler}
                />
            </Form>
        </CustomModal>
    )
}