import {Avatar, Button, ButtonGroup, Counter, Div, Flex, FormItem, Group, Input, Panel, PanelHeader, RichCell, SegmentedControl, Spinner, SubnavigationBar, SubnavigationButton, UsersStack, VisuallyHidden} from "@vkontakte/vkui";
import {VkUser} from "../../components/VkUser";
import * as React from "react";
import {allClientsGroups} from "../../services/vk/VkClientGroupService";
import {useEffect} from "react";
import {numToWord} from "../../helpers/StrHelper";
import {Icon16StarCircleFillYellow, Icon24Comment, Icon24Filter, Icon24Gift, Icon24Like, Icon24ListLikeFill, Icon24Repost, Icon24Search, Icon24SubscriptionsOutline, Icon24Subtitles, Icon24UserAdd} from "@vkontakte/icons";
import {useRouteNavigator} from "@vkontakte/vk-mini-apps-router";
import CityService from "../../services/city/CityService";
import {FormField} from "../../components/form/FormField";
import {Form} from "../../components/form/Form";

export const VkGroupsListPanel = ({...props}) => {
    const [groupsType, setGroupsType] = React.useState('all');
    const [groups, setGroups] = React.useState([]);
    const [fetching, setFetching] = React.useState(true);
    const [cities, setCities] = React.useState([]);
    const cityService = new CityService(props.setPopout);
    const [loading, setLoading] = React.useState(false);
    const [formData, setFormData] = React.useState({
        city_id: props.user.city_id
    });

    const fetchPanelData = async () => {
        await fetchGroups();
        if (!cities.length) {
            await cityService.all().then(res => {
                setCities(cityService.list)
            });
        }
    }

    const handler = (name, value) => {
        // Обновляем объект formData, сохраняя предыдущее состояние
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const fetchGroups = async () => {
        setFetching(true)
        await allClientsGroups({filters: {groupType: groupsType, city_id: formData.city_id, name: formData.name}}).then((response) => {
            setGroups(response);
            setFetching(false)
        })
    }

    const onFilter = async () => {
        await fetchGroups();
    }

    useEffect(() => {
        fetchPanelData();
    }, [groupsType])

    return (
        <Panel id={'vkGroupsListPanel'}>
            <PanelHeader after={<VkUser user={props.user} mode={'plain'} />}>Сообщества</PanelHeader>
            <Group>
                <FormField
                    type="input"
                    name={'name'}
                    placeholder="Введите название сообщества"
                    value={formData.name}
                    setValue={handler}
                />
                <FormItem style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingLeft: 0, paddingTop: 0}}>
                    <FormField
                        type={'customSelect'}
                        placeholder="Введите название города"
                        searchable
                        options={cities}
                        name={'city_id'}
                        value={formData.city_id}
                        setValue={handler}
                    />
                    <Button
                        before={<Icon24Search />}
                        stretched={true}
                        appearance={'positive'}
                        size={'l'}
                        loading={loading}
                        onClick={() => onFilter()}
                    />
                </FormItem>
                <Div>
                    <SegmentedControl
                        value={groupsType}
                        onChange={(value) => setGroupsType(value)}
                        options={[
                            {
                                'label': 'Все сообщества',
                                'value': 'all',
                                'aria-label': 'Все сообщества',
                            },
                            {
                                'label': 'Мои сообщества',
                                'value': 'my',
                                'aria-label': 'Мои сообщества',
                            },
                        ]}
                    />
                </Div>
                {
                    !fetching ?
                        groups.length ?
                            groups.map((group, index) => (
                                <RichCell
                                    key={index}
                                    onClick={() => props.routeNavigator.push('/groups/' + group.group_id)}
                                    subhead={group.city}
                                    before={<Avatar size={72} src={group.photo} />}
                                    caption={group.description}
                                    after={group.actions.length + ' ' + numToWord(group.actions.length, ['задание', 'задания', 'заданий'])}
                                    afterCaption={group.gifts.length + ' ' + numToWord(group.gifts.length , ['подарок', 'подарка', 'подарков'])}
                                    bottom={
                                        group.userFriendsWithSubscribe.length ?
                                            <UsersStack photos={group.userFriendsWithSubscribe}>
                                                {group.userFriendsWithSubscribe.length} {numToWord(group.userFriendsWithSubscribe.length , ['общий', 'общих', 'общих'])} {numToWord(group.userFriendsWithSubscribe.length , ['друг', 'друга', 'друзей'])}
                                            </UsersStack>
                                            : null
                                    }
                                >
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        {group.name} <span style={{display: 'flex', alignItems: 'center', paddingLeft: 5}}><Icon16StarCircleFillYellow style={{paddingRight: 2}} /> {group.rating}</span>
                                    </div>
                                </RichCell>
                            )) : <Div style={{textAlign: 'center'}}>
                                Сообщества в вашем городе не найдены, попробуйте выбрать другой город. Или воспользуйтесь фильтром - Мои сообщества.
                    </Div>
                        : <Spinner size={'medium'} />
                }
            </Group>
        </Panel>
    )
}