import { createSlice } from '@reduxjs/toolkit'

export const appErrorsSlice = createSlice({
    name: 'appErrors',
    initialState: {
        error: null,
    },
    reducers: {
        addAppErrors: (state, action) => {
            state.error = {
                message: action.payload,
                time: new Date().getTime()
            }
        },
        clearAppErrors: (state) => {
            state.error = null
        },
    },
})

// Action creators are generated for each case reducer function
export const { addAppErrors, clearAppErrors } = appErrorsSlice.actions

export default appErrorsSlice.reducer