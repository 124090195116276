import {Avatar, Button, ButtonGroup, Placeholder, Spinner} from "@vkontakte/vkui";
import * as React from "react";
import {Icon12Delete, Icon12Pencil, Icon56UsersOutline} from "@vkontakte/icons";
import ActionService from "../../services/action/ActionService";
import {useRouteNavigator} from "@vkontakte/vk-mini-apps-router";
import {GroupContentList} from "../vkUI/GroupContentList";
import {GroupContent} from "../vkUI/GroupContent";
import {useEffect} from "react";
import {setAdminActionsInStore, deleteAdminActionInStore} from "../../features/actions/adminActionsSlice";
import store from "../../store";
import {useSelector} from "react-redux";

export const AdminActionsList = ({limit, groupId, showAllLink, addLink}) => {
    const actionService = new ActionService();
    const [fetching, setFetching] = React.useState(true);
    const actions = useSelector(state => state.adminActions.list);
    const routeNavigator = useRouteNavigator();

    const fetchPanelData = async () => {
        await actionService.all({limit: limit, vk_group_id: groupId}).then(res => {
            store.dispatch(setAdminActionsInStore(actionService.list));
            setFetching(false);
        });
    }

    const onClickDeleteAction = async (action) => {
        await actionService.delete(action.uuid).then(res => {
            store.dispatch(deleteAdminActionInStore(action.id))
        })
    }

    useEffect(() => {
        fetchPanelData()
    }, []);

    return (
        <GroupContent header={'Действия'} showAllLink={showAllLink ? '/admin/groups/' + groupId + '/actions' : null} addLink={addLink ? '/admin/groups/' + groupId + '/actions/create' : null}>
            {
                !fetching ?
                    actions.length ?
                        actions.map((action, index) => (
                            <GroupContentList
                                key={action.id}
                                content={action.name}
                                subhead={action.show_type === 'group' ? 'Используется в сообществе' : 'Используется в бустере'}
                                before={action.vkGroup ? <Avatar size={40} src={action.vkGroup.photo} /> : null}
                                text={action.vkGroup ? action.vkGroup.name : null}
                                caption={action.link ? action.link.link : null}
                                after={
                                    <ButtonGroup>
                                        <Button before={<Icon12Pencil />} onClick={() => routeNavigator.push('/admin/actions/update/' + action.uuid)} mode="primary" />
                                        <Button before={<Icon12Delete />} onClick={() => onClickDeleteAction(action)} mode={'secondary'} />
                                    </ButtonGroup>
                                }
                            />
                        )) :  <Placeholder icon={<Icon56UsersOutline />}>
                            Пока еще нет действий
                        </Placeholder> : <Spinner size={'medium'} />
            }
        </GroupContent>
    )
}