import * as React from "react";
import {useState} from "react";
import VkUserSubscriptionService from "../../services/vk/VkUserSubscriptionService";
import {CustomModal} from "../../components/vkUI/CustomModal";
import {Form} from "../../components/form/Form";
import {useRouteNavigator} from "@vkontakte/vk-mini-apps-router";
import {FormField} from "../../components/form/FormField";

export const EnterGiftCodeModal = ({...props}) => {
    const vkUserSubscriptionService = new VkUserSubscriptionService();

    const routeNavigator = useRouteNavigator();

    const [fetching, setFetching] = useState(false);
    const [formData, setFormData] = React.useState({});

    const onClickCheckGift = async () => {
        setFetching(true)
        await vkUserSubscriptionService.activate(formData).then(res => {
            setTimeout(() => {
                setFetching(false);
                routeNavigator.hideModal();
            }, 1000);
        }).catch(function (error) {
            setFetching(false);
        });
    }

    const handler = (name, value) => {
        // Обновляем объект formData, сохраняя предыдущее состояние
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <CustomModal
            id={'enter_gift_code_modal'}
            headerText={'Ввести промокод'}
        >

            <Form loading={fetching} onSubmit={onClickCheckGift} submitButtonText={'Активировать'}>
                <FormField
                    type={'input'}
                    title={'Код подарка'}
                    name={'gift_code'}
                    value={formData.gift_code}
                    disabled={fetching}
                    setValue={handler}
                />
            </Form>
        </CustomModal>
    )
}