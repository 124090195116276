import {Avatar, Group, Link, SimpleCell, Spinner} from "@vkontakte/vkui";
import * as React from "react";

export const VkGroupCard = ({vkGroup}) => {
    return (
        <Group>
            {
                vkGroup ?
                    <SimpleCell
                        before={<Avatar src={vkGroup.photo} />}
                        subtitle={
                            <Link target={'_blank'} href={"https://vk.com/" + vkGroup.screen_name}>
                                {vkGroup.screen_name}
                            </Link>}
                    >
                        {vkGroup.name}
                    </SimpleCell> : <Spinner size={'medium'} />
            }
        </Group>
    )
}