import {Avatar, Button, Placeholder, Spinner} from "@vkontakte/vkui";
import * as React from "react";
import {Icon56UsersOutline} from "@vkontakte/icons";
import {useRouteNavigator} from "@vkontakte/vk-mini-apps-router";
import {GroupContentList} from "../vkUI/GroupContentList";
import {GroupContent} from "../vkUI/GroupContent";
import {useEffect} from "react";
import VkUserService from "../../services/vk/VkUserService";

export const AdminClientsList = ({limit, groupId, showAllLink, addLink}) => {
    const [fetching, setFetching] = React.useState(true);
    const [users, setUsers] = React.useState([]);
    const routeNavigator = useRouteNavigator();
    const vkUserService = new VkUserService();

    const fetchPanelData = async () => {
        try {
            await vkUserService.all({limit: limit, vk_group_id: groupId}).then(res => {
                setUsers(vkUserService.list);
                setFetching(false);
            });
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchPanelData()
    }, []);

    return (
        <GroupContent header={'Подписчики'} showAllLink={users ? '/admin/clients' : null} actions={null}>
            {
                !fetching ?
                    users.length ?
                        users.map((user, index) => (
                            <GroupContentList
                                key={index}
                                before={<Avatar size={48} src={user.photo} />}
                                content={user.first_name + ' ' + user.last_name}
                                after={user.city_name}
                                afterCaption={user.phone}
                                caption={user.created_at}
                                actions={
                                    <Button onClick={() => routeNavigator.push('/admin/clients/show/' + user.uuid)} mode="primary" size="s">
                                        Подробнее
                                    </Button>
                                }
                            />
                        ))
                        :  <Placeholder icon={<Icon56UsersOutline />}>
                            Пока еще нет подписчиков
                        </Placeholder> : <Spinner size={'medium'} />
            }
        </GroupContent>
    )
}