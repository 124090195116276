import {Button, Group, Header, IconButton, ModalCard, SimpleCell, Spacing, Spinner} from "@vkontakte/vkui";
import {Icon28AddCircleFillBlue, Icon28GiftOutline} from "@vkontakte/icons";
import * as React from "react";
import GiftService from "../../services/gift/GiftService";
import BoosterService from "../../services/booster/BoosterService";
import {useEffect, useState} from "react";
import {useParams} from "@vkontakte/vk-mini-apps-router";

export const GiftUserSelectModal = ({...props}) => {
    const giftService = new GiftService();
    const boosterService = new BoosterService();

    const params = useParams();

    const [fetching, setFetching] = useState(true);

    const [booster, setBooster] = useState(null);
    const [gifts, setGifts] = useState([]);
    const [actions, setActions] = useState([]);
    const [boosterIsCompleted, setBoosterIsCompleted] = useState(false);

    const checkBoosterIsCompleted = () => {
        if (actions.length) {
            let completedActions = actions.filter(action => action.is_completed);
            setBoosterIsCompleted(completedActions.length === actions.length);
            if (completedActions.length !== actions.length) {
                props.routeNavigator.push('/boosters/show/' + booster.uuid);
            }
        }
    }

    const fetchData = async () => {
        await boosterService.show(params.uuid).then(res => {
            if (boosterService.model.userHasBoosterGift) {
                props.routeNavigator.push('/boosters/show/' + params.uuid);
            } else {
                setBooster(boosterService.model);
                setActions(boosterService.model.actions);
                setGifts(boosterService.model.gifts);
            }
        }).then(res => {
            setFetching(false);
        })
    }

    useEffect(() => {
        if (!booster) {
            fetchData();
        }
        checkBoosterIsCompleted();
    }, [actions]);

    const getGift = (giftUuid) => {
        giftService.getSelectedGift({booster_uuid: booster.uuid, gift_uuid: giftUuid}).then(res=> {
            props.routeNavigator.push('/gift/code/show/' + res.uuid);
        });
    }

    return (
        <ModalCard
            id={'booster_gifts_modal'}
            onClose={() => props.routeNavigator.back()}
            icon={<Icon28GiftOutline />}
            header="Вы можете выбрать любой подарок из этого списка"
            settlingHeight={100}
            dynamicContentHeight={true}
            actions={
                <React.Fragment>
                    <Group header={<Header mode="secondary">Список подарков</Header>}>
                        {
                            !fetching && boosterIsCompleted ?
                                gifts.map((gift, index) => {
                                    return <SimpleCell
                                        key={index}
                                        before={<Icon28GiftOutline />}
                                        after={
                                            <IconButton label="Выбрать" onClick={() => getGift(gift.uuid)}>
                                                <Icon28AddCircleFillBlue />
                                            </IconButton>
                                        }
                                        subtitle={gift.description}
                                        onClick={() => getGift(gift.uuid)}
                                    >
                                        {gift.name}
                                    </SimpleCell>
                                }) : <Spinner size={'medium'} />
                        }
                    </Group>
                    <Spacing size={16} />
                    <Button
                        size="l"
                        mode="primary"
                        stretched
                        onClick={() => props.routeNavigator.back()}
                    >
                        Закрыть
                    </Button>
                </React.Fragment>
            }
        />
    )
}