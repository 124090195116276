import { createSlice } from '@reduxjs/toolkit'

export const formErrorsSlice = createSlice({
    name: 'formErrors',
    initialState: {
        list: [],
    },
    reducers: {
        addFormErrors: (state, action) => {
            state.list = action.payload
        },
        clearFormErrors: (state) => {
            state.list = []
        },
    },
})

// Action creators are generated for each case reducer function
export const { addFormErrors, clearFormErrors } = formErrorsSlice.actions

export default formErrorsSlice.reducer