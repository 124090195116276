import {CustomPanel} from "../../../components/vkUI/CustomPanel";
import {PanelHeader, PanelHeaderBack} from "@vkontakte/vkui";
import * as React from "react";
import {useRouteNavigator} from "@vkontakte/vk-mini-apps-router";
import ActionStatService from "../../../services/stat/ActionStatService";
import {useEffect} from "react";
import VkGroupStatService from "../../../services/stat/VkGroupStatService";
import ActionStat from "../../../components/stat/ActionStat";
import VkGroupStat from "../../../components/stat/VkGroupStat";

export const AdminStatPanel = () => {
    const routeNavigator = useRouteNavigator();
    const actionStatService = new ActionStatService();
    const vkGroupStatService = new VkGroupStatService();

    const [actionStat, setActionStat] = React.useState([]);
    const [actionStatLoading, setActionStatLoading] = React.useState(true);
    const [actionStatPeriod, setActionStatPeriod] = React.useState('all');

    const [vkGroupStat, setVkGroupStat] = React.useState([]);
    const [vkGroupStatLoading, setVkGroupStatLoading] = React.useState(true);
    const [vkGroupStatPeriod, setVkGroupStatPeriod] = React.useState('all');

    const fetchPanelData = () => {
        actionStatService.all().then(res => {
            setActionStat(actionStatService.list);
            setActionStatLoading(false);
        })
        vkGroupStatService.all().then(res => {
            setVkGroupStat(vkGroupStatService.list);
            setVkGroupStatLoading(false);
        })
    }

    useEffect(() => {
        fetchPanelData()
    }, [])

    const onChangeActionStatPeriod = (period) => {
        setActionStatPeriod(period);
        setActionStatLoading(true);
        actionStatService.all({period: period}).then(res => {
            setActionStat(actionStatService.list);
            setActionStatLoading(false);
        })
    }

    const onChangeVkGroupStatPeriod = (period) => {
        setVkGroupStatPeriod(period);
        setVkGroupStatLoading(true);
        vkGroupStatService.all({period: period}).then(res => {
            setVkGroupStat(vkGroupStatService.list);
            setVkGroupStatLoading(false);
        })
    }

    return (
        <CustomPanel id={'adminStatPanel'}>
            <PanelHeader
                delimiter="spacing"
                before={<PanelHeaderBack onClick={() => routeNavigator.back()}/>}
            >
                Аналитика
            </PanelHeader>
            <ActionStat stat={actionStat} loading={actionStatLoading} period={actionStatPeriod} onChangePeriod={onChangeActionStatPeriod} />
            <VkGroupStat stat={vkGroupStat} loading={vkGroupStatLoading} period={vkGroupStatPeriod} onChangePeriod={onChangeVkGroupStatPeriod} />
        </CustomPanel>
    )
}