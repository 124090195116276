import * as React from 'react';
import {createRoot} from 'react-dom/client';
import {AdaptivityProvider, ConfigProvider, AppRoot,} from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';
import {App} from "./App";
import {RouterProvider} from '@vkontakte/vk-mini-apps-router';
import {createBrowserRouter} from "react-router-dom";
import store from './store'
import {Provider} from 'react-redux'

const router = createBrowserRouter([
    {
        path: '/login',
        panel: 'auth',
        view: 'auth',
    },
    {
        path: '/',
        panel: 'profile',
        view: 'profile',
    },
    {
        path: '/user/update',
        modal: 'update_user_data_modal',
        panel: 'profile',
        view: 'profile',
    },
    {
        path: '/user/enter-gift-code',
        modal: 'enter_gift_code_modal',
        panel: 'profile',
        view: 'profile',
    },
    {
        path: '/groups',
        panel: 'vkGroupsListPanel',
        view: 'groups',
    },
    {
        path: '/groups/:vkGroupId',
        panel: 'vkGroupShowPanel',
        view: 'groups',
    },
    {
        path: '/groups/:vkGroupId/reviews',
        panel: 'vkGroupReviewShowPanel',
        view: 'groups',
    },
    {
        path: '/action/:uuid',
        modal: 'vk_action_modal'
    },
    {
        path: '/groups/:vkGroupId/action/:uuid/send-review',
        modal: 'vk_send_review_modal',
        panel: 'vkGroupShowPanel',
        view: 'groups',
    },
    {
        path: '/groups/:vkGroupId/action/:uuid/set-likes-on-last-posts',
        modal: 'vk_set_likes_on_last_posts_modal',
        panel: 'vkGroupShowPanel',
        view: 'groups',
    },
    {
        path: '/groups/:vkGroupId/action/:uuid/set-like',
        modal: 'vk_set_like_modal',
        panel: 'vkGroupShowPanel',
        view: 'groups',
    },
    {
        path: '/groups/:vkGroupId/action/:uuid/send-comment',
        modal: 'vk_send_comment_modal',
        panel: 'vkGroupShowPanel',
        view: 'groups',
    },
    {
        path: '/groups/:vkGroupId/action/:uuid/repost',
        modal: 'vk_repost_modal',
        panel: 'vkGroupShowPanel',
        view: 'groups',
    },
    {
        path: '/boosters/show/:uuid',
        panel: 'boosterShowPanel',
        view: 'booster',
    },
    {
        path: '/boosters/show/:uuid/gifts',
        modal: 'booster_gifts_modal',
        panel: 'boosterShowPanel',
        view: 'booster',
    },
    {
        path: '/gifts',
        panel: 'gift',
        view: 'gift',
    },
    {
        path: '/gifts/buy/:id',
        modal: 'buy_gift_modal',
    },
    {
        path: '/gift/code/show/:uuid',
        modal: 'gift_qr_code_modal',
        panel: 'gift',
        view: 'gift',
    },
    {
        path: '/admin',
        panel: 'admin',
        view: 'admin',
    },
    {
        path: '/admin/user/settings',
        modal: 'admin_user_settings_modal',
        panel: 'admin',
        view: 'admin',
    },
    {
        path: '/admin/actions',
        panel: 'adminActionsListPanel',
        view: 'admin',
    },
    {
        path: '/admin/stat',
        panel: 'adminStatPanel',
        view: 'admin',
    },
    {
        path: '/admin/stat/group/:groupId',
        panel: 'adminStatGroupPanel',
        view: 'admin',
    },
    {
        path: '/admin/gifts',
        panel: 'adminGiftsListPanel',
        view: 'admin',
    },
    {
        path: '/admin/groups/update/:groupId',
        modal: 'admin_vk_group_update_modal',
        panel: 'admin',
        view: 'admin',
    },
    {
        path: '/admin/groups/show/:groupId',
        panel: 'adminVkGroupShowPanel',
        view: 'admin',
    },
    {
        path: '/admin/boosters',
        panel: 'adminBoosterListPanel',
        view: 'admin',
    },
    {
        path: '/admin/groups/:groupId/boosters/create',
        modal: 'booster_create_modal',
        panel: 'admin',
        view: 'admin',
    },
    {
        path: '/admin/boosters/update/:uuid',
        modal: 'booster_update_modal',
        panel: 'admin',
        view: 'admin',
    },
    {
        path: '/admin/groups/:groupId/gifts/create',
        modal: 'gift_create_modal',
        panel: 'admin',
        view: 'admin',
    },
    {
        path: '/admin/gifts/update/:uuid',
        modal: 'gift_update_modal',
        panel: 'admin',
        view: 'admin',
    },
    {
        path: '/admin/gifts/code/create',
        modal: 'giftCode_create_modal',
        panel: 'admin',
        view: 'admin',
    },
    {
        path: '/admin/groups/:groupId/actions/create',
        modal: 'action_create_modal',
        panel: 'admin',
        view: 'admin',
    },
    {
        path: '/admin/actions/update/:uuid',
        modal: 'action_update_modal',
        panel: 'admin',
        view: 'admin',
    },
    {
        path: '/admin/clients',
        panel: 'adminVkUsersPanel',
        view: 'admin',
    },
    {
        path: '/admin/clients/show/:uuid',
        modal: 'client_show_modal',
        panel: 'adminVkUsersPanel',
        view: 'admin',
    },
    {
        path: '/admin/clients/show/:uuid/actions',
        modal: 'client_show_actions_modal',
        panel: 'adminVkUsersPanel',
        view: 'admin',
    },
    {
        path: '/admin/clients/show/:uuid/gifts',
        modal: 'client_show_gifts_modal',
        panel: 'adminVkUsersPanel',
        view: 'admin',
    },
    {
        path: '/admin/check-gift',
        panel: 'adminCheckGiftPanel',
        view: 'admin',
    },
    {
        path: '/admin/clients/actions',
        panel: 'adminVkUsersActionsPanel',
        view: 'admin',
    },
]);

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <ConfigProvider>
        <AdaptivityProvider>
            <AppRoot>
                <RouterProvider router={router} notFoundRedirectPath="/">
                    <Provider store={store}>
                        <App/>
                    </Provider>
                </RouterProvider>
            </AppRoot>
        </AdaptivityProvider>
    </ConfigProvider>
    ,
);