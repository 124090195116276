import {PanelHeader, SplitLayout} from "@vkontakte/vkui";
import * as React from "react";

export const Layout = ({popout, modal, hasHeader, children}) => {
    return (
        <SplitLayout
            popout={popout}
            modal={modal}
            header={hasHeader && <PanelHeader delimiter="none"/>} style={{justifyContent: 'center'}}>
            {children}
        </SplitLayout>
    )
}