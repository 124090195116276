import Service from "../Service";
import axios from "../../plugins/axios-plugin";

class VkAdminGroupService extends Service{
    basePath = 'vk/admin/groups'

    syncGroups = async () => {
        return await axios.get(this.basePath + '/sync');
    }
}

export default VkAdminGroupService;