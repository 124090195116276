import {Avatar, Div, Flex, Group, Image, Link, SimpleCell, Spinner} from "@vkontakte/vkui";
import * as React from "react";
import {Icon24Comment, Icon24Like, Icon24Repost, Icon28CommentCircleFillBlue, Icon28LikeCircleFillRed, Icon28LikeFillRed, Icon28RepostCircleFillGreen} from "@vkontakte/icons";

export const VkActionDataContent = ({actionData}) => {
    return (
        actionData &&
        <Group style={{padding: 16}}>
            {actionData.text}
            {
                actionData.attachments ?
                    <Flex style={{marginTop: 10}}>
                        {
                            actionData.attachments.map((attachment) => {
                                return attachment.type === 'photo' ?
                                    <Image src={attachment.photo.sizes[attachment.photo.sizes.length - 1].url} size={256}/> : null
                            })
                        }
                    </Flex>
                : null
            }
            <Flex align={'center'} justify={'start'} style={{marginTop: 10}}>
                <Flex align={'center'} style={{paddingRight: 15}}>
                    <Icon28LikeCircleFillRed style={{paddingRight: 5}} />
                    {actionData.likes.count}
                </Flex>
                <Flex align={'center'} style={{paddingRight: 15}}>
                    <Icon28CommentCircleFillBlue style={{paddingRight: 5}} />
                    {actionData.comments.count}
                </Flex>
                <Flex align={'center'}>
                    <Icon28RepostCircleFillGreen style={{paddingRight: 5}} />
                    {actionData.reposts.count}
                </Flex>
            </Flex>
        </Group>
    )
}