import axios from "../../plugins/axios-plugin";
import Service from "../Service";

class BoosterService extends Service {
    basePath = 'booster';

    recommended = async (params) => {
        try {
            const response = await axios.get(this.basePath + '/recommended', {
                params: params,
            });
            return response.data;
        } catch (error) {
            throw new Error(error.response.data.message);
        }
    };
}

export default BoosterService;