import {Avatar, CellButton, Group, Header, InfoRow, ModalPage, ModalPageHeader, PanelHeaderBack, PanelHeaderButton, PanelHeaderClose, RichCell, SimpleCell, Spinner} from "@vkontakte/vkui";
import {Icon24Dismiss, Icon28CheckCircleFill, Icon28GifOutline, Icon28Services} from "@vkontakte/icons";
import * as React from "react";
import {useParams} from "@vkontakte/vk-mini-apps-router";
import {useEffect} from "react";
import VkUserService from "../../services/vk/VkUserService";
import {CustomModal} from "../../components/vkUI/CustomModal";

export const ClientShowGiftsModal = ({...props}) => {
    const vkUserService = new VkUserService();
    const [fetching, setFetching] = React.useState(true);
    const params = useParams();
    const [gifts, setGifts] = React.useState(null);

    const fetchData = async () => {
        await vkUserService.getGifts(params.uuid).then(res => {
            setGifts(res.data.data)
            setFetching(false);
        })
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <CustomModal id={'client_show_gifts_modal'} headerText={'Полученные подарки'}>
            {
                !fetching ? <Group>
                    {
                        gifts ? gifts.map((gift, index) => (
                            <RichCell
                                key={index}
                                subhead={gift.vkGroup.name}
                                before={
                                    <Avatar size={72} src={gift.vkGroup.photo}>
                                        {
                                            gift.is_used ?
                                                <Avatar.Badge>
                                                    <Icon28CheckCircleFill />
                                                </Avatar.Badge> : null
                                        }
                                    </Avatar>
                                }
                                text="Дата получения"
                                caption={gift.created_at}
                            >
                                {gift.gift.name}
                            </RichCell>
                        )) : 'Нет подарков'
                    }
                </Group> : <Group><Spinner size={'medium'} /></Group>
            }
        </CustomModal>
    )
}