import {Button, Headline, Image, ModalCard, Spacing, Text, Spinner, Title, Subhead} from "@vkontakte/vkui";
import {Icon28GiftOutline} from "@vkontakte/icons";
import * as React from "react";
import {useParams} from "@vkontakte/vk-mini-apps-router";
import {useEffect, useState} from "react";
import GiftCodeService from "../../services/gift/GiftCodeService";
import vkQr from '@vkontakte/vk-qr';

export const GiftShowQrCodeModal = ({...props}) => {
    const giftCodeService = new GiftCodeService();
    const params = useParams();

    const [loading, setLoading] = useState(true);
    const [gift, setGift] = useState(null);

    const fetchData = async () => {
        await giftCodeService.show(params.uuid).then(res => {
            setGift(giftCodeService.model);
            setLoading(false);
        });
    }

    const getQrCode = () => {
        return vkQr.createQR('https://app.lbooster.ru/admin/check-gift?code=' + gift.code, {
            qrSize: 256,
            isShowLogo: true,
            logoData: gift.vkGroup.photo
        });
    }

    useEffect(() => {
        fetchData()
    }, []);

    return (
        <ModalCard
            id={'gift_qr_code_modal'}
            onClose={() => props.routeNavigator.back()}
            icon={<Icon28GiftOutline />}
            header="Для использования подарка покажите этот QR-код сотруднику сообщества"
            subheader={gift && gift.vkGroup ? gift.vkGroup.name : null}
            settlingHeight={100}
            dynamicContentHeight={true}
            actions={
                gift ? <React.Fragment>
                    <div style={{textAlign: 'center', marginBottom: '10px'}}>
                        <Title level="1">
                            {gift.code}
                        </Title>
                        <Headline level="1">
                            {gift.gift.name}
                        </Headline>
                        <Subhead>{gift.gift.description}</Subhead>
                        {
                            gift.available_at && <Title level="3" style={{color: 'tomato'}}>
                                Активировать до {gift.available_at}
                            </Title>
                        }
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <div dangerouslySetInnerHTML={{__html: getQrCode()}}/>
                    </div>
                    <Spacing size={16}/>
                    <Button
                        size="l"
                        mode="primary"
                        stretched
                        onClick={() => props.routeNavigator.back()}
                    >
                        Закрыть
                    </Button>
                </React.Fragment> : <Spinner size={'medium'} />
            }
        />
    )
}