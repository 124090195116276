import {CustomPanel} from "../../../components/vkUI/CustomPanel";
import {PanelHeader, PanelHeaderBack} from "@vkontakte/vkui";
import * as React from "react";
import {useParams, useRouteNavigator} from "@vkontakte/vk-mini-apps-router";
import {useEffect} from "react";
import VkGroupMembersStat from "../../../components/stat/VkGroupMembersStat";
import VkGroupMembersStatService from "../../../services/stat/VkGroupMembersStatService";

export const AdminStatGroupPanel = () => {
    const vkGroupMembersStatService = new VkGroupMembersStatService();
    const params = useParams();

    const routeNavigator = useRouteNavigator();

    const [vkGroupMembersStat, setVkGroupMembersStat] = React.useState([]);
    const [vkGroupMembersStatLoading, setVkGroupMembersStatLoading] = React.useState(true);
    const [vkGroupMembersStatPeriod, setVkGroupMembersStatPeriod] = React.useState('all');

    const fetchPanelData = () => {
        vkGroupMembersStatService.getActivityStat(params.groupId).then(res => {
            setVkGroupMembersStat(res.data.data);
            setVkGroupMembersStatLoading(false);
        })
    }

    const vkGroupMembersStatOnChangePeriod = (period) => {
        setVkGroupMembersStatPeriod(period);
        setVkGroupMembersStatLoading(true);
    }

    useEffect(() => {
        fetchPanelData()
    }, [])

    return (
        <CustomPanel id={'adminStatGroupPanel'}>
            <PanelHeader
                delimiter="spacing"
                before={<PanelHeaderBack onClick={() => routeNavigator.back()}/>}
            >
                Аналитика сообщества
            </PanelHeader>
            <VkGroupMembersStat
                stat={vkGroupMembersStat}
                loading={vkGroupMembersStatLoading}
                period={vkGroupMembersStatPeriod}
                onChangePeriod={vkGroupMembersStatOnChangePeriod}
            />
        </CustomPanel>
    )
}