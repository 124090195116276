import {CardGrid, ContentCard, Group, Spinner} from "@vkontakte/vkui";
import * as React from "react";

export const UserAnalytic = ({data}) => {
    return (
        data ? <Group mode={'plain'}>
            <CardGrid size="s">
                <ContentCard
                    mode={"shadow"}
                    subtitle="Выполнено действий"
                    header={data.actionsCount}
                />
                <ContentCard
                    mode={"shadow"}
                    subtitle="Получено подарков"
                    header={data.giftsCount}
                />
                <ContentCard
                    mode={"shadow"}
                    subtitle="Заработано баллов"
                    header={data.scoresCount}
                />
            </CardGrid>
        </Group> : <Spinner size="medium" style={{ margin: '20px 0' }} />
    )
}