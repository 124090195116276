import Service from "../Service";
import axios from "../../plugins/axios-plugin";

class VkGroupMembersStatService extends Service {
    basePath = 'stat/vk-group/members'

    getActivityStat = async (ownerId) => {
        return axios.get(`${this.basePath}/activity-stat?group_id=${ownerId}`)
    }
}

export default VkGroupMembersStatService;