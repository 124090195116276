import {CustomModal} from "../../components/vkUI/CustomModal";
import * as React from "react";
import {Group} from "@vkontakte/vkui";
import {useEffect} from "react";
import CityService from "../../services/city/CityService";
import {FormField} from "../../components/form/FormField";
import {CustomSpinner} from "../../components/vkUI/CustomSpinner";
import {Form} from "../../components/form/Form";

export const VkGroupsFilerModal = ({id, setPopout}) => {
    const [cities, setCities] = React.useState([]);
    const cityService = new CityService(setPopout);
    const [loading, setLoading] = React.useState(false);
    const [formData, setFormData] = React.useState({});

    const fetchData = async () => {
        await cityService.all().then(res => {
            setCities(cityService.list)
        });
    }

    const handler = (name, value) => {
        // Обновляем объект formData, сохраняя предыдущее состояние
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const onSubmit = async () => {

    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <CustomModal id={id} headerText={'Фильтр'}>
            <Group>
                {
                    !loading ?
                        <Form loading={loading} onSubmit={onSubmit} submitButtonText={'Показать результаты'}>
                            <FormField
                                title={'Город сообщества'}
                                type={'customSelect'}
                                placeholder="Введите название города"
                                searchable
                                options={cities}
                                name={'city_id'}
                                value={formData.city_id}
                                setValue={handler}
                            />
                        </Form> : <CustomSpinner />
                }
            </Group>
        </CustomModal>
    )
}