import {Avatar, Button, ButtonGroup, Group, Panel, PanelHeader, PanelHeaderBack, Placeholder, RichCell, Spinner} from "@vkontakte/vkui";
import * as React from "react";
import {useEffect, useState} from "react";
import VkUserService from "../../../services/vk/VkUserService";
import {Icon56ServicesOutline, Icon56UserAddOutline} from "@vkontakte/icons";

export const AdminVkUsersPanel = ({openError, routeNavigator}) => {
    const [fetching, setFetching] = useState(true);
    const [users, setUsers] = useState([]);
    const vkUserService = new VkUserService();

    const fetchPanelData = async () => {
        try {
            await vkUserService.all().then(res => {
                setUsers(vkUserService.list);
                setFetching(false);
            });
        } catch (error) {
            openError(error.message)
        }
    }

    useEffect(() => {
        fetchPanelData();
    }, []);

    return (
        <Panel>
            <PanelHeader
                delimiter="spacing"
                before={<PanelHeaderBack onClick={() => routeNavigator.back()} />}
            >
                Подписчики
            </PanelHeader>
            {
                !fetching ? <Group id="tab-content-clients" aria-labelledby="tab-groups" role="tabpanel">
                    {
                        users.length ?
                            users.map((user, index) => (
                                <RichCell
                                    key={index}
                                    before={<Avatar size={72} src={user.photo} />}
                                    subhead={user.city_name}
                                    text={user.phone}
                                    after={user.created_at}
                                    actions={
                                        <ButtonGroup mode="horizontal" gap="s" stretched>
                                            <Button onClick={() => routeNavigator.push('/admin/clients/show/' + user.uuid)} mode="primary" size="s">
                                                Подробнее
                                            </Button>
                                        </ButtonGroup>
                                    }
                                >
                                    {user.first_name + ' ' + user.last_name}
                                </RichCell>
                            )) : <Placeholder
                                icon={<Icon56UserAddOutline />}
                                header="Никто из пользователей еще не прошел бустер"
                            >
                                Разместите QR-код на прохождение и наблюдайте как растет ваше сообщество.
                            </Placeholder>
                    }
                </Group> : <Group><Spinner size={'medium'} /></Group>
            }
        </Panel>
    )
}