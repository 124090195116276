import React from "react";
import {CellButton, Group, Panel, PanelHeader, PanelHeaderBack} from "@vkontakte/vkui";
import {VkUser} from "../../../components/VkUser";
import {useRouteNavigator} from "@vkontakte/vk-mini-apps-router";
import {AdminGiftsList} from "../../../components/gift/AdminGiftsList";
import {Icon28AddOutline} from "@vkontakte/icons";

export const AdminGiftsListPanel = ({...props}) => {
    const routeNavigator = useRouteNavigator();

    return (
        <Panel>
            <PanelHeader before={<PanelHeaderBack onClick={() => routeNavigator.back()} />} after={<VkUser user={props.user} mode={'plain'} />}>
                Подарки
            </PanelHeader>
            <Group>
                <CellButton onClick={() => routeNavigator.push('/admin/gifts/create')} before={<Icon28AddOutline/>}>
                    Добавить подарок
                </CellButton>
            </Group>
            <AdminGiftsList addLink={false} showAllLink={false} />
        </Panel>
    )
}