import {Div, Text} from "@vkontakte/vkui";
import {Form} from "../form/Form";
import * as React from "react";
import VkService from "../../services/vk/VkService";
import {useParams, useRouteNavigator} from "@vkontakte/vk-mini-apps-router";
import {FormField} from "../form/FormField";

export const VkSendReviewActionForm = ({setPopout}) => {
    const vkService = new VkService(setPopout);

    const routeNavigator = useRouteNavigator();
    const queryParams = new URLSearchParams(window.location.search);

    const [loading, setLoading] = React.useState(false);
    const [formData, setFormData] = React.useState({});
    const params = useParams();
    const handleChange = (name, value) => {
        // Обновляем объект formData, сохраняя предыдущее состояние
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const onSubmitHandler = async() => {
        try {
            await vkService.sendReview({
                review: formData.review,
                action_uuid: params.uuid,
                booster_uuid: queryParams.has('booster_uuid') ? queryParams.get('booster_uuid') : null
            }).then(res => {
                routeNavigator.hideModal()
                setLoading(false)
            })
        } catch (exception) {
            setLoading(false)
        }
    }

    return (
        <Form loading={loading} onSubmit={onSubmitHandler} submitButtonText={'Отправить'}>
            <FormField
                title={'Текст отзыва'}
                type={'input'}
                value={formData.review}
                setValue={handleChange}
                name={'review'}
            />
        </Form>
    )
}