import * as React from "react";
import {Button, Group, Image, ModalPage, ModalPageHeader, PanelHeaderButton, PanelHeaderClose, Spacing, Spinner} from "@vkontakte/vkui";
import {Icon24Dismiss} from "@vkontakte/icons";
import {useEffect} from "react";
import BoosterService from "../../services/booster/BoosterService";
import {useParams} from "@vkontakte/vk-mini-apps-router";

export const BoosterQrCodeModal = ({...props}) => {
    const boosterService = new BoosterService();

    const [fetching, setFetching] = React.useState(true);
    const params = useParams();

    const [booster, setBooster] = React.useState(null);

    const fetchData = async () => {
        try {
            await boosterService.show(params.uuid).then(res => {
                setBooster(res);
                setFetching(false)
            });
            props.setErrors(null);
        } catch (error) {
            props.openError(error.message);
        }
    }

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <ModalPage
            id={'booster_qr_code_modal'}
            onClose={() => props.routeNavigator.back()}
            hideCloseButton={props.platform === 'ios'}
            settlingHeight={100}
            dynamicContentHeight={true}
            header={
                <ModalPageHeader
                    before={
                        props.sizeX.compact &&
                        props.platform === 'android' && (
                            <PanelHeaderClose className={props.sizeX.compact.className} onClick={() => props.routeNavigator.back()} />
                        )
                    }
                    after={
                        props.platform === 'ios' && (
                            <PanelHeaderButton onClick={() => props.routeNavigator.back()}>
                                <Icon24Dismiss />
                            </PanelHeaderButton>
                        )
                    }
                >
                    QR-код на бустер
                </ModalPageHeader>
            }
        >
            {
                !fetching ? <Group>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <Image
                            size={300}
                            src={booster.qrCode.src}
                            borderRadius={6}
                        />
                    </div>
                    <Spacing size={16} />
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <Button
                            size="l"
                            mode="primary"
                            stretched
                            onClick={() => window.open(booster.qrCode.src)}
                        >
                            Скачать
                        </Button>
                    </div>
                </Group> : <Group><Spinner size={'medium'}/></Group>
            }
        </ModalPage>
    )
}