import {RichCell} from "@vkontakte/vkui";
import * as React from "react";

export const GroupContentList = ({onClick, caption, afterCaption, before, subhead, text, after, content, actions}) => {
    return (
        <RichCell
            onClick={onClick}
            before={before}
            subhead={subhead}
            text={text}
            after={after}
            actions={actions}
            caption={caption}
            afterCaption={afterCaption}
        >
            {content}
        </RichCell>
    )
}