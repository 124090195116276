import {Avatar, Group, ModalPage, ModalPageHeader, PanelHeaderBack, PanelHeaderButton, RichCell, Spinner} from "@vkontakte/vkui";
import {Icon24Dismiss, Icon28CheckCircleFill} from "@vkontakte/icons";
import * as React from "react";
import {useParams} from "@vkontakte/vk-mini-apps-router";
import {useEffect} from "react";
import VkUserService from "../../services/vk/VkUserService";
import {CustomModal} from "../../components/vkUI/CustomModal";

export const ClientShowBoostersModal = ({...props}) => {
    const vkUserService = new VkUserService()
    const [fetching, setFetching] = React.useState(true);
    const params = useParams();
    const [userActions, setUserActions] = React.useState([]);

    const fetchData = async () => {
        await vkUserService.getActions(params.uuid).then(res => {
            setUserActions(res.data.data)
            setFetching(false);
        })
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <CustomModal
            id={'client_show_actions_modal'} headerText={'Выполненные действия'}>

            {
                !fetching ? <Group>
                    {
                        userActions ? userActions.map((userAction, index) => (
                            <RichCell
                                key={index}
                                before={
                                    <Avatar size={72} src={userAction.action.vkGroup.photo}>
                                        {
                                            userAction.is_completed ?
                                                <Avatar.Badge>
                                                    <Icon28CheckCircleFill />
                                                </Avatar.Badge> : null
                                        }
                                    </Avatar>
                                }
                                text="Дата прохождения"
                                caption={userAction.created_at}
                            >
                                {userAction.action.name}
                            </RichCell>
                        )) : 'Нет выполненныех действий'
                    }
                </Group> : <Group><Spinner size={'medium'} /></Group>
            }
        </CustomModal>
    )
}