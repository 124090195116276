import {Div, Group, Panel, PanelHeader, Placeholder, ScreenSpinner} from "@vkontakte/vkui";
import * as React from "react";
import * as VKID from '@vkid/sdk';
import {useEffect, useState} from "react";
import axios from 'axios';
import {Icon56UsersOutline} from "@vkontakte/icons";

export const AuthPanel = ({...props}) => {
    const [loading, setLoading] = useState(true);

    const clearQueryParams = () => {
        window.location.replace('https://app.lbooster.ru');
    };

    const getUser = () => {
        props.setPopout(<ScreenSpinner state="loading" />);

        axios.get('https://api.lbooster.ru/api/user', {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        })
            .then(response => {
                localStorage.setItem('user', JSON.stringify(response.data.data.user));
            })
            .catch(error => {
                // Обработка ошибок
                console.error('Ошибка:', error);
            })
            .finally(() => {
                clearQueryParams();

                props.setPopout(<ScreenSpinner state="done">Успешно</ScreenSpinner>);
                setTimeout(props.clearPopout, 1000);
        });
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const token = queryParams.get('token');

        if (token) {
            localStorage.setItem('token', token);
            if (localStorage.getItem('token')) {
                getUser();
            }
        } else {
            VKID.Config.set({
                app: 51807747, // Идентификатор приложения.
                redirectUrl: "https://api.lbooster.ru/login/vk",
                state: 'dj29fnsadjsd82...' // Произвольная строка состояния приложения.
            });

            const oneTap = new VKID.OneTap();
            const container = document.getElementById('VkIdSdkOneTap');
            if (container) {
                oneTap.render({ container: container, scheme: VKID.Scheme.LIGHT, lang: VKID.Languages.RUS });
            }
        }

        setLoading(false);
    }, []);
    return (
        <Panel>
            <PanelHeader>
                Авторизация
            </PanelHeader>
            <Group>
                <Placeholder
                    icon={<Icon56UsersOutline/>}
                    header="Войдите с помощью Вконтакте"
                    action={null}
                >
                    Авторизуйтесь чтобы получить доступ ко всем функциям Lbooster.
                </Placeholder>
                <div style={{margin: '0 20px 50px'}}>
                    <div id="VkIdSdkOneTap"/>
                </div>
            </Group>
        </Panel>
    )
}