import {Avatar, Placeholder, Spinner} from "@vkontakte/vkui";
import * as React from "react";
import {Icon56UsersOutline} from "@vkontakte/icons";
import {useRouteNavigator} from "@vkontakte/vk-mini-apps-router";
import {GroupContentList} from "../vkUI/GroupContentList";
import {GroupContent} from "../vkUI/GroupContent";
import {useEffect} from "react";
import ActionUserService from "../../services/action/ActionUserService";

export const AdminClientsActionsList = ({limit, groupId, showAllLink, addLink}) => {
    const actionUserService = new ActionUserService();
    const [fetching, setFetching] = React.useState(true);
    const [userActions, setUserActions] = React.useState([]);
    const routeNavigator = useRouteNavigator();

    const fetchPanelData = async () => {
        try {
            await actionUserService.all({limit: limit, vk_group_id: groupId}).then(res => {
                setUserActions(actionUserService.list);
                setFetching(false);
            });
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchPanelData()
    }, []);

    return (
        <GroupContent header={'Последние действия подписчиков'} showAllLink={userActions ? '/admin/clients/actions' : null} actions={null}>
            {
                !fetching ?
                    userActions.length ?
                        userActions.map((userAction, index) => (
                            <GroupContentList
                                key={index}
                                before={<Avatar size={48} src={userAction.user.photo} />}
                                content={userAction.user.first_name + ' ' + userAction.user.last_name}
                                after={userAction.user.city_name}
                                text={userAction.action.name}
                                afterCaption={userAction.user.phone}
                                caption={userAction.created_at}
                            />
                        )) : <Placeholder icon={<Icon56UsersOutline />}>
                            Пока еще нет действий
                        </Placeholder> : <Spinner size={'medium'} />
            }
        </GroupContent>
    )
}
