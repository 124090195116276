import {FormField} from "../form/FormField";
import * as React from "react";
import VkAdminGroupService from "../../services/vk/VkAdminGroupService";
import {CustomSpinner} from "../vkUI/CustomSpinner";
import {useEffect} from "react";

export const VkGroupSelect = ({formData, handler}) => {
    const vkAdminGroupService = new VkAdminGroupService();
    const [groups, setGroups] = React.useState(null);
    const [fetching, setFetching] = React.useState(true);

    const fetchGroups= async () => {
        await vkAdminGroupService.all().then(res => {
            setGroups(vkAdminGroupService.list);
        });
    }

    useEffect(() => {
        fetchGroups().then(res =>
            setFetching(false)
        )
    }, [])

    return (
        !fetching ?
            <FormField
                title={'Сообщество'}
                type={'select'}
                name={'vk_group_id'}
                value={formData.vk_group_id}
                setValue={handler}
                options={groups}
                optionLabel={'name'}
                optionId={'group_id'}
                optionImage={'photo'}
            /> : <CustomSpinner />
    )
}