import {useState} from "react";
import GiftService from "../../../services/gift/GiftService";
import {Button, FormItem, Group, Header, InfoRow, Input, Panel, PanelHeader, PanelHeaderBack, SimpleCell} from "@vkontakte/vkui";
import * as React from "react";
import {useSearchParams} from "@vkontakte/vk-mini-apps-router";
import {CustomSimpleCell} from "../../../components/vkUI/CustomSimpleCell";
import {CustomFormItem} from "../../../components/form/CustomFormItem";
import GiftCodeService from "../../../services/gift/GiftCodeService";
import {Form} from "../../../components/form/Form";
import {FormField} from "../../../components/form/FormField";

export const AdminCheckGiftPanel = ({routeNavigator, setPopout, clearPopout, openError}) => {
    const giftService = new GiftService(setPopout);
    const giftCodeService = new GiftCodeService(setPopout);

    const [fetching, setFetching] = useState(false);
    const [giftCodeData, setGiftCodeData] = useState(null);

    const [params, setParams] = useSearchParams();

    const [formData, setFormData] = React.useState({
        gift_code: params.get('code') ?? ''
    });

    const onClickCheckGift = async () => {
        setFetching(true)
        try {
            await giftCodeService.checkGiftCode(formData).then(res => {
                setGiftCodeData(res.data.data);
                setFetching(false);
            });
        } catch (error) {
            setFetching(false);
        }
    }

    const onClickSetGiftIsUsed = async () => {
        setFetching(true)
        try {
            await giftCodeService.setGiftIsUsed(formData).then(res => {
                setGiftCodeData(res.data.data);
                setFetching(false);
            });
        } catch (error) {
            setFetching(false);
        }
    }

    const handler = (name, value) => {
        // Обновляем объект formData, сохраняя предыдущее состояние
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    return (
        <Panel>
            <PanelHeader
                delimiter="spacing"
                before={<PanelHeaderBack onClick={() => routeNavigator.back()} />}
            >
                Проверить подарок
            </PanelHeader>
            <Group id="tab-content-check-gift" aria-labelledby="tab-check-gift" role="tabpanel">
                <Form loading={fetching} submitButtonText={'Проверить'} onSubmit={onClickCheckGift}>
                    <FormField
                        title={'Код подарка'}
                        type={'input'}
                        name={'gift_code'}
                        value={formData.gift_code}
                        setValue={handler}
                    />
                </Form>
                {
                    giftCodeData ? <>
                        <Group mode={'plain'} header={<Header mode="secondary">Информация о подарке</Header>}>
                            {
                                giftCodeData.available_at &&
                                    <SimpleCell>
                                        <InfoRow style={{color: 'tomato'}} header="Активировать до ">{giftCodeData.available_at}</InfoRow>
                                    </SimpleCell>
                            }
                            <SimpleCell>
                                <InfoRow header="Статус подарка">{giftCodeData.is_used ? <span style={{color: 'tomato'}}>Использован</span> : <span style={{color: 'green'}}>Не использован</span>}</InfoRow>
                            </SimpleCell>
                            <SimpleCell multiline>
                                <InfoRow header="Название">{giftCodeData.gift.name}</InfoRow>
                            </SimpleCell>
                            <CustomSimpleCell header={'Описание'} text={giftCodeData.gift.description} />
                        </Group>
                        <Group mode={'plain'} header={<Header mode="secondary">Информация о пользователе</Header>}>
                            <SimpleCell multiline>
                                <InfoRow header="Имя">{giftCodeData.vkUser.first_name + ' ' + giftCodeData.vkUser.last_name}</InfoRow>
                            </SimpleCell>
                            <SimpleCell>
                                <InfoRow header="Город">{giftCodeData.vkUser.city_name}</InfoRow>
                            </SimpleCell>
                            <SimpleCell>
                                <InfoRow header="Телефон">{giftCodeData.vkUser.phone}</InfoRow>
                            </SimpleCell>
                            <SimpleCell>
                                <InfoRow header="Дата рождения">{giftCodeData.vkUser.birth_date}</InfoRow>
                            </SimpleCell>
                            <SimpleCell>
                                <InfoRow header="Ссылка на профиль">
                                    <a target={'_blank'} href={'https://vk.com/' + giftCodeData.vkUser.vk_screen_name}>
                                        {'https://vk.com/' + giftCodeData.vkUser.vk_screen_name}
                                    </a>
                                </InfoRow>
                            </SimpleCell>
                            {
                                !giftCodeData.is_used ?
                                    <FormItem>
                                        <Button
                                            stretched='stretched'
                                            disabled={fetching}
                                            size='m'
                                            loading={fetching}
                                            onClick={() => onClickSetGiftIsUsed()}
                                        >
                                            Отменить использованным
                                        </Button>
                                    </FormItem> : null
                            }
                        </Group>
                    </> : null
                }
            </Group>
        </Panel>
    )
}