import {CellButton, Group, Panel, PanelHeader, PanelHeaderBack} from "@vkontakte/vkui";
import {VkUser} from "../../../components/VkUser";
import {AdminActionsList} from "../../../components/action/AdminActionsList";
import {Icon28AddOutline} from "@vkontakte/icons";
import React from "react";
import {useRouteNavigator} from "@vkontakte/vk-mini-apps-router";

export const AdminActionsListPanel = ({...props}) => {

    const routeNavigator = useRouteNavigator();

    return (
        <Panel>
            <PanelHeader
                before={<PanelHeaderBack onClick={() => props.routeNavigator.back()} />}
                after={<VkUser user={props.user} mode={'plain'} />}
            >
                Действия
            </PanelHeader>
            <Group>
                <CellButton onClick={() => routeNavigator.push('/admin/actions/create')} before={<Icon28AddOutline/>}>
                    Добавить действие
                </CellButton>
            </Group>
            <AdminActionsList />
        </Panel>
    )
}