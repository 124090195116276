import React, { useState } from 'react';
import Star from './Star'; // Убедитесь, что путь к компоненту Star правильный

const RatingSelector = ({ onRatingChange }) => {
    const [rating, setRating] = useState(0);

    const handleStarClick = (index) => {
        setRating(index + 1);
        if (onRatingChange) {
            onRatingChange(index + 1);
        }
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center', padding: '12px 16px' }}>
            {Array.from({ length: 5 }).map((_, index) => (
                <Star
                    key={index}
                    filled={index < rating}
                    onClick={() => handleStarClick(index)}
                />
            ))}
        </div>
    );
};

export default RatingSelector;
