import axios from "../../plugins/axios-plugin";
import Service from "../Service";

class BoosterSessionService extends Service {
    basePath = '/booster/session/'

    startBusterSession = async (booster) => {
        try {
            const response = await axios.get(this.basePath + booster + '/start');
            return response.data.data;
        } catch (error) {
            throw new Error(error.response.data.message);
        }
    };

    getRecentBoosters = async (params) => {
        try {
            const response = await axios.get(this.basePath + 'recent', {
                params: params,
            });
            return response.data.data;
        } catch (error) {
            throw new Error(error.response.data.message);
        }
    };
}

export default BoosterSessionService;