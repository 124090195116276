import {Button, Div} from "@vkontakte/vkui";
import * as React from "react";

export const Form = ({ children, loading, onSubmit, submitButtonText }) => {
    return (
        <>
            {children}
            <Div>
                <Button
                    stretched={true}
                    appearance={'positive'}
                    size={'l'}
                    loading={loading}
                    onClick={() => onSubmit()}
                >
                    {submitButtonText ? submitButtonText : 'Сохранить'}
                </Button>
            </Div>
        </>
    )
}