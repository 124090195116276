import {Avatar, Card, ContentCard, Image, RichCell, Title, UsersStack} from "@vkontakte/vkui";
import {Icon20VotestTransferCircleFillTurquoise, Icon28CheckCircleFill} from "@vkontakte/icons";
import * as React from "react";
import {numToWord} from "../../helpers/StrHelper";

export const CustomCard = ({onClick, image, friends, title, description, checked, scores_count}) => {
    return (
        <RichCell
            onClick={onClick}
            before={
                <Avatar size={72} src={image}>
                    <Avatar.Badge>
                        {
                            checked ? <Icon28CheckCircleFill/> : null
                        }
                    </Avatar.Badge>
                </Avatar>
            }
            caption={description}
            after={
                <React.Fragment>
                    {
                        scores_count && <div style={{display: 'flex', padding: 5, alignItems: 'center', gap: '3px', color: 'black', borderRadius: 10, fontWeight: 700}}>
                            {scores_count} <Icon20VotestTransferCircleFillTurquoise/>
                        </div>
                    }
                </React.Fragment>
            }
            bottom={
                friends && friends.length ? <UsersStack photos={friends}>
                    {friends.length} {numToWord(friends.length, ['общий', 'общих', 'общих'])} {numToWord(friends.length, ['друг', 'друзей', 'друзей'])}
                </UsersStack> : null
            }
        >
            {title}
        </RichCell>
    )
}