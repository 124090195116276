import * as React from "react";
import {CustomModal} from "../../components/vkUI/CustomModal";
import {ActionForm} from "../../components/action/ActionForm";

export const ActionCreateModal = ({...props}) => {

    return (
        <CustomModal id={'action_create_modal'} headerText={'Добавить действие'}>
            <ActionForm
                setPopout={props.setPopout}
            />
        </CustomModal>
    )
}