import {Button, ButtonGroup, Group, Header, Placeholder, Spinner, Tooltip} from "@vkontakte/vkui";
import * as React from "react";
import {Icon28CommentCircleFillBlue, Icon28LikeCircleFillRed, Icon28RepostCircleFillGreen, Icon28UsersCircleFillBlue, Icon28UserStarOutline, Icon28ViewOutline, Icon56StatisticsOutline} from "@vkontakte/icons";
import {useRouteNavigator} from "@vkontakte/vk-mini-apps-router";

const VkGroupStat = ({stat, loading, period, onChangePeriod}) => {
    const routeNavigator = useRouteNavigator();

    return (
        <Group
            header={
                <Header style={{paddingLeft: 0, paddingRight: 0, alignItems: 'center', paddingBottom: 10}}
                        aside={
                            <ButtonGroup mode="horizontal" gap="m">
                                <Button
                                    onClick={() => onChangePeriod('daily')}
                                    appearance={period === 'daily' ? 'accent' : 'neutral'}
                                    mode={period === 'daily' ? 'primary' : 'link'}
                                    size={'s'}
                                >
                                    Сутки
                                </Button>
                                <Button
                                    onClick={() => onChangePeriod('weekly')}
                                    appearance={period === 'weekly' ? 'accent' : 'neutral'}
                                    mode={period === 'weekly' ? 'primary' : 'link'}
                                    size={'s'}
                                >
                                    Неделя
                                </Button>
                                <Button
                                    onClick={() => onChangePeriod('monthly')}
                                    appearance={period === 'monthly' ? 'accent' : 'neutral'}
                                    mode={period === 'monthly' ? 'primary' : 'link'}
                                    size={'s'}
                                >
                                    Месяц
                                </Button>
                                <Button
                                    onClick={() => onChangePeriod('all')}
                                    appearance={period === 'all' ? 'accent' : 'neutral'}
                                    mode={period === 'all' ? 'primary' : 'link'}
                                    size={'s'}
                                >
                                    Все время
                                </Button>
                            </ButtonGroup>
                        }>Статистика сообществ</Header>
            }
        >
            {
                !loading ?
                    stat.length ? <table width="100%" style={{borderSpacing: 0, borderCollapse: 'collapse'}}>
                            <thead>
                            <tr style={{backgroundColor: '#f0f2f5'}}>
                                <th scope="col" align={'left'} style={{fontSize: '12px', paddingLeft: 10}}>Сообщество</th>
                                <th scope="col" align={'center'}>
                                    <Tooltip placement="right" text="Количество подписчиков, которые пользуются Lbooster">
                                        <Icon28UserStarOutline/>
                                    </Tooltip>
                                </th>
                                <th scope="col" align={'center'}>
                                    <Tooltip placement="right" text="Количество новых подписчиков">
                                        <Icon28UsersCircleFillBlue/>
                                    </Tooltip>
                                </th>
                                <th scope="col" align={'center'}>
                                    <Tooltip placement="right" text="Количество лайков">
                                        <Icon28LikeCircleFillRed/>
                                    </Tooltip>
                                </th>
                                <th scope="col" align={'center'}>
                                    <Tooltip placement="right" text="Количество комментариев">
                                        <Icon28CommentCircleFillBlue/>
                                    </Tooltip>
                                </th>
                                <th scope="col" align={'center'}>
                                    <Tooltip placement="right" text="Количество репостов">
                                        <Icon28RepostCircleFillGreen/>
                                    </Tooltip>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                stat.map((element, index) => (
                                    <tr key={index} style={{height: '40px', borderBottom: '1px solid #dce1e6'}}>
                                        <td align={'left'} style={{fontSize: '12px', paddingLeft: 10}}>
                                            <span
                                                onClick={() => routeNavigator.push('/admin/stat/group/' + element.group_id)}
                                                style={{cursor: 'pointer', color: 'blue'}}
                                            >
                                                {element.name}
                                            </span>
                                        </td>
                                        <td align={'center'} style={{fontSize: '12px'}}>
                                            {element.members_in_lbooster}
                                        </td>
                                        <td align={'center'}>
                                            {
                                                element.members > 0 ? <span style={{color: 'lightgreen', fontSize: '12px'}}>+{element.members}</span> :
                                                    element.members === 0 ? <span style={{fontSize: '12px'}}>{element.members}</span> :
                                                        <span style={{color: 'tomato', fontSize: '12px'}}>-{element.members}</span>
                                            }
                                        </td>
                                        <td align={'center'} style={{fontSize: '12px'}}>
                                            {element.likes_count}
                                        </td>
                                        <td align={'center'} style={{fontSize: '12px'}}>
                                            {element.comments_count}
                                        </td>
                                        <td align={'center'} style={{fontSize: '12px'}}>
                                            {element.reposts_count}
                                        </td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                        : <Placeholder icon={<Icon56StatisticsOutline/>}>
                            Статистика по сообществам появятся на следующий день после их создания.
                        </Placeholder> :
                    <Spinner size={'medium'}/>
            }
        </Group>
    )
}

export default VkGroupStat;