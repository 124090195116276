import React from 'react';
import {Icon16StarCircleFillGray, Icon16StarCircleFillYellow} from "@vkontakte/icons";

const Star = ({ filled, onClick }) => {
    return (
        !filled ?
            <Icon16StarCircleFillGray height={24} width={24} onClick={onClick} style={{margin: '0 2px', cursor: 'pointer'}} />
            :<Icon16StarCircleFillYellow height={24} width={24} onClick={onClick} style={{margin: '0 2px', cursor: 'pointer'}} />
    );
};

export default Star;
