import {Avatar, Button, ButtonGroup, Placeholder, RichCell} from "@vkontakte/vkui";
import {Icon28CheckCircleFill, Icon28Gift, Icon56GiftOutline} from "@vkontakte/icons";
import * as React from "react";

export const GiftsList = ({gifts, routeNavigator}) => {
    const showGiftQrCode = (gift) => {
        routeNavigator.push(`/gift/code/show/` + gift.uuid)
    }

    return (
        gifts.length ? gifts.map((gift, index) => (
                <RichCell
                    key={index}
                    before={<Avatar size={72} src={gift.vkGroup ? gift.vkGroup.photo : null} />}
                    subhead={gift.vkGroup ? gift.vkGroup.name : null}
                    text={gift.gift.name}
                    caption={gift.gift.description}
                    after={
                        <RichCell.Icon aria-hidden>
                            {
                                gift.is_used ? <Icon28CheckCircleFill /> : <Icon28Gift />
                            }
                        </RichCell.Icon>
                    }
                    actions={
                        <ButtonGroup mode="horizontal" gap="s" stretched>
                            {
                                gift.is_used ? <Button disabled={true} mode="secondary" size="s">
                                    Подарок использован
                                </Button> : <Button onClick={() => showGiftQrCode(gift)} mode="primary" size="s">
                                    Показать QR-код
                                </Button>
                            }

                        </ButtonGroup>
                    }
                >
                    {gift.code}
                </RichCell>
            )) :
            <Placeholder
                icon={<Icon56GiftOutline />}
                header="Вы еще не получили ни одного подарка"
                action={<Button onClick={() => routeNavigator.push('/groups')} size="m">Выбрать сообщество</Button>}
            >
                Начните выполнять действия и получайте подарки за их прохождение!
            </Placeholder>
    )
}