export const numToWord = (value, words) => {
    value = Math.abs(value) % 100;
    var num = value % 10;
    if(value > 10 && value < 20) return words[2];
    if(num > 1 && num < 5) return words[1];
    if(num == 1) return words[0];
    return words[2];
}

export const trim = (str, length) => {
    if (str.length > length) {
        return str.substring(0, length) + '...';
    } else {
        return str;
    }
}