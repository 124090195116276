import { configureStore } from '@reduxjs/toolkit'
import formErrorsSlice from './features/errors/formErrorsSlice'
import adminActionsSlice from "./features/actions/adminActionsSlice";
import adminGiftsSlice from "./features/gifts/adminGiftsSlice";
import adminBoostersSlice from "./features/boosters/adminBoostersSlice";
import appErrorsSlice from "./features/errors/appErrorsSlice";

export default configureStore({
    reducer: {
        appErrors: appErrorsSlice,
        formErrors: formErrorsSlice,
        adminActions: adminActionsSlice,
        adminGifts: adminGiftsSlice,
        adminBoosters: adminBoostersSlice,
    },
})