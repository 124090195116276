import { createSlice } from '@reduxjs/toolkit'

export const adminActionsSlice = createSlice({
    name: 'adminActionsSlice',
    initialState: {
        list: [],
    },
    reducers: {
        setAdminActionsInStore: (state, action) => {
            state.list = action.payload;
        },
        addAdminActionInStore: (state, action) => {
            state.list.unshift(action.payload);
        },
        updateAdminActionInStore(state, action) {
            state.list = state.list.map(item => {
                if (item.id === action.payload.id) {
                    return {
                        ...item,
                        ...action.payload
                    };
                }
                return item;
            });
        },
        deleteAdminActionInStore(state, action) {
            state.list = state.list.filter(item => {
                return item.id !== action.payload;
            })
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    setAdminActionsInStore,
    addAdminActionInStore,
    updateAdminActionInStore,
    deleteAdminActionInStore
} = adminActionsSlice.actions

export default adminActionsSlice.reducer