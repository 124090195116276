import {Div, Headline, Text} from "@vkontakte/vkui";
import * as React from "react";

export const CustomSimpleCell = ({header, text}) => {
    return (
        <Div>
            <Headline style={{color: '#6d7885', fontSize: '13px'}} level="2">{header}</Headline>
            <Text>
                {text}
            </Text>
        </Div>
    )
}