import { createSlice } from '@reduxjs/toolkit'

export const adminBoostersSlice = createSlice({
    name: 'adminBoostersSlice',
    initialState: {
        list: [],
    },
    reducers: {
        setAdminBoostersInStore: (state, action) => {
            state.list = action.payload;
        },
        addAdminBoosterInStore: (state, action) => {
            state.list.unshift(action.payload);
        },
        updateAdminBoosterInStore(state, action) {
            state.list = state.list.map(item => {
                if (item.id === action.payload.id) {
                    return {
                        ...item,
                        ...action.payload
                    };
                }
                return item;
            });
        },
        deleteAdminBoosterInStore(state, action) {
            state.list = state.list.filter(item => {
                return item.id !== action.payload;
            })
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    setAdminBoostersInStore,
    addAdminBoosterInStore,
    updateAdminBoosterInStore,
    deleteAdminBoosterInStore
} = adminBoostersSlice.actions

export default adminBoostersSlice.reducer